<template>
  <layout name="OrganizLayout">



    <div class="Home">
       <page-header/>
      <!-- HERO -->
      <section>
        <div class="uk-container ">
          <div class="uk-text-center uk-margin">
            <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
              <li>
                <router-link tag="a" :to="{name: 'organization'}">
                    <span >Home</span>
                </router-link>
              </li>
              <li>
                <span>Student requests</span>
              </li>
            </ul>
          </div>

          <div class="uk-width-1-1 uk-width-expand@m">

            <div class="uk-grid uk-grid-small uk-margin-medium-bottom uk-margin-medium-top" uk-margin>
              <div class="uk-inline">
                <input v-model="name" placeholder="Name" class="uk-input" type="text"/>
              </div>
              <div class="uk-inline">
                <input v-model="email" placeholder="Email" class="uk-input" type="email"/>
              </div>
              <div class="uk-inline">
                <input v-model="sdate" class="uk-input" type="date"/>
<!--                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>-->
              </div>
              <div class="uk-inline">
                <input v-model="edate" class="uk-input" type="date"/>
<!--                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>-->
              </div>
              <div>
                <button type="button" @click="searchDate" class="uk-button uk-button-default">Search</button>
              </div>
            </div>

            <div class="uk-card uk-card-default uk-card-small tm-ignore-container">

              <header class="uk-card-header">
                <div class="uk-grid-small uk-flex-middle" uk-grid>
                  <div
                    class="uk-width-1-1 uk-width-expand@s uk-flex uk-flex-center uk-flex-left@s uk-text-small"
                  >
                              <span
                                style="margin-right:10px"
                                class="uk-flex uk-flex-middle"
                              >Sort by:</span>

                    <div uk-form-custom="target: > * > span:first-child">
                      <select v-model="sortselected" @change="onChangeSort($event)"
                              class="sortBy">
                        <option value="norm">Normal</option>
                        <option value="name">Name</option>
                        <option value="email">Email</option>
                        <option value="enabled">Enabled</option>
                      </select>
                      <button
                        class="uk-button uk-button-default"

                        tabindex="-1"
                      >

                        <span uk-icon="icon: chevron-down"></span>
                      </button>
                    </div>
                  </div>
                  <div
                    class="uk-width-1-1 uk-width-auto@s uk-flex uk-flex-center uk-flex-middle"
                  >

                    <a @click="confirmAll" class="uk-button uk-button-default uk-button-small uk-hidden@m"
                       style="color: #000000"
                    >
                     <span
                       class="uk-margin-xsmall-right"
                       uk-icon="icon: check; ratio: .95;"
                     ></span>Confirm all
                    </a>
                    <div class="tm-change-view uk-margin-small-left">
                      <ul class="uk-subnav uk-iconnav js-change-view">
                        <li>
                          <a @click="confirmAll" class="uk-button uk-button-default uk-button-small uk-hidden"

                             title="Confirm all"><span
                            class="uk-margin-xsmall-right"
                            uk-icon="icon: check; ratio: .95;"
                          ></span>Confirm all
                          </a>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </header>
              <div class="uk-card-body">
                <table v-if="students.length > 0" id="example"
                       class="uk-table uk-table-hover uk-table-striped uk-table-responsive"
                       style="width:100%">
                  <thead>
                  <tr>
                    <th><label>
                      <input class="uk-checkbox" @change="selectAll" v-model="allSelected" type="checkbox"/>
                    </label></th>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Requested at</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody v-if="students.length > 0">

                  <tr v-for="item in students"
                      :item="item"
                      :key="item.requestId">
                    <td><label>
                      <input class="uk-checkbox" type="checkbox" v-model="requestIds" @change="select" :id="item.requestId" :value="item.requestId"/></label></td>
                    <td>{{item.requestId}}</td>
                    <td>{{item.userName}}</td>
                    <td>{{item.userEmail}}</td>
                    <td>{{item.userPhone}}</td>
                    <td>{{item.requestDate | moment}}</td>
                    <td>
                      <a @click="confirmItem(item.requestId)"
                           class="uk-button uk-button-primary uk-button-small uk-text-center"><span
                        class="uk-margin-xsmall-right"
                        uk-icon="icon: check; ratio: .95;"></span>
                      </a>
                      <a href="javascript:void(0)" @click="deleteItem(item.requestId)"
                         class="uk-button uk-button-danger uk-button-small uk-margin-small-left uk-text-center">
                        <span uk-icon="icon: trash; ratio: 1;"></span>
                      </a>
                    </td>
                  </tr>
                  </tbody>

                </table>
                <div v-else class="uk-alert-primary " uk-alert>

                  <p class="uk-text-center">Empty.</p>
                </div>
              </div>
              <div class="uk-card-footer uk-text-center">

                <div class="uk-flex uk-flex-center">

                  <v-pagination v-model="currentPage"
                                :page-count="pages"
                                :classes="uikitClasses"
                                :labels="Labels"
                                @change="onChange"></v-pagination>

                </div>

              </div>


            </div>
          </div>

        </div>
      </section>
      <page-footer/>
    </div>

  </layout>
</template>


<script scoped>
  import Layout from '../../layouts/Layout'
  import UserService from '@/services/UserService'
  import moment from 'moment'
  import vPagination from '@/components/globals/vue-plain-pagination'
  import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
  const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")

  import {mapGetters} from 'vuex'
  export default {
    name: 'Home',
    components: {
      Layout,
      vPagination,
      PageHeader,
      PageFooter
    },
    data() {
      return {
        allSelected: false,
        requestIds: [],
        sdate: null,
        edate: null,
        name: null,
        email: null,
        error: null,
        sortselected: 'norm',
        students: [],
        currentPage: 1,
        pages: 0,
        uikitClasses: {
          ul: 'uk-pagination',
          li: '',
          liActive: 'uk-active',
          liDisable: 'uk-disabled',
          button: 'page-link'
        },
        Labels: {
          first: 'First',
          prev: '<span uk-pagination-previous></span>',
          next: '<span uk-pagination-next></span>',
          last: "<span uk-icon='chevron-double-right'; ratio = '1.2'></span>"
        }
      }
    },
    filters: {
      moment: function (date) {
        return moment(date).format('YYYY.MM.DD')
        // return moment(date).format('YYYY-MM-DD, h:mm:ss a')
      }
    },
    methods: {
      confirmAll: function () {
        if (this.requestIds.length == 0) {
          alert("Please check request")
          return;
        }
        this.confirmItems()
      },
      selectAll: function () {
        this.requestIds = []
        if (this.allSelected) {
          for (var i = 0; i < this.students.length; i++) {
            this.requestIds.push(this.students[i].requestId)
          }
        }
      },
      select: function () {
        this.allSelected = false
      },
      confirmItem: function (value) {
        this.allSelected = false
        this.requestIds.push(value)
        if (this.requestIds.length == 0) {
          alert("Please check request")
          return;
        }
        this.confirmItems()
      },
      async confirmItems() {
        try {
          const response = await UserService.confrimRequests(this.requestIds);
          if (response.data.status == 200) {
            if (response.data.success == true) {
              alert(response.data.message)
              this.loaddata(this.currentPage)
            }
          }
        } catch (error) {
          console.log(error)
          this.scrollToTop()
        }
      },
      async deleteItem(rIds) {
        try {
          // const headers = {
          //   'Content-Type': 'application/json',
          //   'Authorization': 'Bearer ' + store.getters.getToken
          // }
          // var _this = this
          // axios.post('https://ubtcloud.me/api/organization/strequest/back', rId,
          //     {headers: headers}).then(function (response) {
          //   if (response.data.status == 200) {
          //     if (response.data.success == true) {
          //       alert(response.data.message)
          //       _this.loaddata(_this.currentPage)
          //     }
          //   }
          // });
          let rsId = {
            rId: rIds
          }
          const response = await UserService.deleteRequests(rsId);
          if (response.data.status == 200) {
            if (response.data.success == true) {
              alert(response.data.message)
              this.loaddata(this.currentPage)
            }
          }
        } catch (error) {
          console.log(error)
          this.scrollToTop()
        }
      },
      // eslint-disable-next-line no-unused-vars
      onChangeSort(event) {
        this.loaddata(this.currentPage)
      },
      searchDate() {
        this.loaddata(this.currentPage)
      },
      async loaddata(page) {
        try {
          if (page > 0) {
            page = page - 1
          }
          const response = await UserService.getStudentRequestByPage({
            name: this.name,
            email: this.email,
            sdate: this.sdate,
            edate: this.edate,
            page: page,
            sort: this.sortselected,
            organizId: this.currentUser.organizs[0].organizId
          });
          if (response.status == 200) {
            this.students = response.data.content;
            // this.currentPage = response.data.page;
            this.pages = response.data.totalPages;
            if (!this.students.length) {
              this.isLoading = false
              return
            }
          }
        } catch (error) {
          console.log(error.response)
          this.error = error.response.data.message;
          this.scrollToTop()
        }
      },

      scrollToTop() {
        window.scrollTo(0, 0)
      }
      ,
      onChange: function () {
        // eslint-disable-next-line no-console
        this.loaddata(this.currentPage)
      }
    },
    computed: {
      ...mapGetters(['isLoggedIn', 'currentUser'])
    }
  }
</script>

<style scoped>
.sortBy {
  border: 1px solid #dee;
  border-radius: 15px;
  padding-left: 12px;
  outline: none;
  opacity: 0;
}
  .uk-pagination > li > a {
    font-size: 18px !important;
  }
</style>
